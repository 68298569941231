<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.43 147.875" x="0px" y="0px">
		<title>Fichier 1066</title>
		<g data-name="Calque 2">
			<g data-name="Calque 2">
				<path :fill="color" class="cls-1" d="M98.93,72.14,60.72,94.21,22.5,116.27a15,15,0,0,1-22.5-13V15A15,15,0,0,1,15,0,14.82,14.82,0,0,1,22.5,2L60.72,24.1,98.93,46.16A15,15,0,0,1,98.93,72.14Z"/>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
  name: 'arrowTools',
  props: ['color']
};
</script>