<template>
    <div class="dashboard__view">
        <v-row class="displayer" no-gutters>
            <v-col lg="5" md="12" sm="12" xs="12">
                <div class="section__tools">
                    <h2>Avant mon rendez-vous</h2>
                    <hr>
                    <div class="list__tools">
                        <ToolBloc
                            :icon="o.icon" 
                            :color="o.color" 
                            :name="o.name" 
                            :desc="o.desc"
                            :interne="o.interne"
                            :link="o.link" 
                            v-for="(o, index) in avant_rdv" 
                            v-bind:key="index"
                        >
                        </ToolBloc>
                    </div>
                </div>
            </v-col>
            <v-col lg="7" md="12" sm="12" xs="12">
                <div class="section__tools">
                    <h2>Pendant mon rendez-vous</h2>
                    <hr>
                    <div class="list__tools">
                        <ToolBloc
                            :icon="o.icon" 
                            :color="o.color" 
                            :name="o.name" 
                            :desc="o.desc"
                            :interne="o.interne"
                            :link="o.link" 
                            v-for="(o, index) in pendant_rendez_vous" 
                            v-bind:key="index"
                        >
                        </ToolBloc>               
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="displayer" no-gutters>
            <v-col lg="5" md="12" sm="12" xs="12">
                <div class="section__tools">
                    <h2>Les outils maison</h2>
                    <hr>
                    <div class="list__tools">
                        <ToolBloc
                            :icon="o.icon" 
                            :color="o.color" 
                            :name="o.name" 
                            :desc="o.desc"
                            :interne="o.interne"
                            :link="o.link" 
                            v-for="(o, index) in outil_maison" 
                            v-bind:key="index"
                        >
                        </ToolBloc>
                    </div>
                </div>
            </v-col>
            <v-col lg="7" md="12" sm="12" xs="12">
                <div class="section__tools">
                    <h2>Contacter le siege</h2>
                    <hr>
                    <div class="list__tools">
                        <ToolBloc
                            :icon="o.icon" 
                            :color="o.color" 
                            :name="o.name" 
                            :desc="o.desc"
                            :interne="o.interne"
                            :link="o.link"
                            v-for="(o, index) in contacter_siege" 
                            v-bind:key="index"
                        >
                        </ToolBloc>                
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ToolBloc from '../components/ToolBloc.vue'
import {outils} from '../outils.js';
import { mapGetters } from 'vuex';

export default {
    name: 'Dashbaord',
    components : {
        ToolBloc,
    },
    data: () => ({
        avant_rdv: [],
        pendant_rendez_vous: [],
        outil_maison: [],
        contacter_siege: []
    }),
    created: function(){
        var user_fonction = this.getIntranetFonction();
        var auth_intranet = this.getIntranetIsLog();
        var auth_adminer  = this.getAdminerToken();
        var token = this.getIntranetToken();
        var token_adminer = this.getAdminerToken();
        
        this.avant_rdv = outils.get_all(
            "avant_rdv", 
            token, token_adminer, 
            user_fonction, 
            auth_intranet, auth_adminer
        );
        
        this.pendant_rendez_vous = outils.get_all(
            "pendant_rendez_vous",
            token, token_adminer,
            user_fonction,
            auth_intranet, auth_adminer
        );
        
        this.outil_maison = outils.get_all(
            "outil_maison",
            token, token_adminer,
            user_fonction,
            auth_intranet, auth_adminer
        );
        
        this.contacter_siege = outils.get_all(
            "contacter_siege",
            token, token_adminer,
            user_fonction,
            auth_intranet, auth_adminer
        );
    },
    methods: {
        ...mapGetters('auth', [
            "getAdminerToken",
            "getIntranetToken",
            "getAdminerIsLog",
            "getIntranetIsLog",
            "getIntranetFonction"
        ])
    }
};
</script>


<style lang="scss">
  .dashboard__view {
    .section__tools {
      h2 {
        text-transform: uppercase;
        font-style: oblique;
        color: #c2c2c2;
        font-weight: 400;
      }
      hr {
        width: 80%;
        height: 5px;
        border-radius: 30px;
        background: #e3862c;
      }
      .list__tools {
        margin-top: 45px;
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        padding: 10px 5px;
      }
    }
    .formulaires__list {
      display: flex;
    }
  }
</style>