<template>
	<div class="tools">
		<a 
			:href="link" 
			target="_blank" 
			v-if="interne == false || interne == undefined"
		>
			<div class="tools__icon" v-html="icon"></div>
			<h3>{{ name }}</h3>
			<hr :style="{ 'background-color': color }">
			<span>{{ desc }}</span>
			<ArrowTools class="link__img" :color="color"></ArrowTools>					
		</a>
		<router-link 
			:to="link" 
			v-if="interne != false && interne != undefined"
		>
			<div class="tools__icon" v-html="icon"></div>
			<h3>{{ name }}</h3>
			<hr :style="{ 'background-color': color }">
			<span>{{ desc }}</span>
			<ArrowTools class="link__img" :color="color"></ArrowTools>					
		</router-link>
	</div>
</template>

<script>
	import ArrowTools from './icon/arrowTools.vue'
	export default {
		name: 'ToolBloc',
		components : {
			ArrowTools
		},
		props: ['icon', 'color', 'name', 'desc', 'link', 'interne']
	};
</script>

<style lang="scss">
	.tools {
		width: 170px;
		height: 192px;
		padding: 20px 10px;
		box-shadow: 0 0 5px #adadad;
		border-radius: 30px;
		margin-right: 25px;
		margin-bottom: 80px;
		position: relative;
		cursor: pointer;
		a {
			text-decoration: none;
			color: black;
		}
		h3 {
			margin: 25px 0 10px 0;
			font-size: 20px;
			line-height: 1em;
			font-weight: 500;
		}
		span {
			font-size: 14px;
		}
		hr {
			margin: 5px auto;
			width: 20% !important;
			border-radius: 30px !important;
			height: 8px !important;
			box-shadow: none !important;
			border: none !important;
		}
		.tools__icon {
			position: absolute;
			top: -25px;
			left: 0; 
			right: 0; 
			margin-left: auto; 
			margin-right: auto;
			svg {
				height: 60px !important;
				background: white;
			}
		}
		svg, img {
			height: 35px;
		} 
		.link__img {
			position: absolute;
			bottom:-22px;
			left: 0; 
			right: 0; 
			margin-left: auto; 
			margin-right: auto;
		}
	}
</style>